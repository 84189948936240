import './App.css';
import ThemeProvider from 'react-bootstrap/ThemeProvider';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import 'bootstrap/dist/css/bootstrap.min.css';
import Mint from './components/Mint.jsx'


function App() {
  return (
    <ThemeProvider
      breakpoints={['xxxl', 'xxl', 'xl', 'lg', 'md', 'sm', 'xs', 'xxs']}
      minBreakpoint="xxs"
    >
      <Container fluid>
        <Row>
          <Col className='text-end pt-4 pe-4'>
            <a href="https://twitter.com/search?q=%23SteezyApeGang" target="_blank"><img alt="#SteezyApeGang" src="/images/steezyapegang.png" className='p-2 float-right' /></a>
            <a href="https://twitter.com/okinagroup" target="_blank"><img alt="Twitter" src="/images/twitter.png" className='p-2 float-right social-icon'  /></a>
            <a href="https://opensea.io/collection/steezyapegang" target="_blank"><img alt="OpenSea" src="/images/opensea.png" className='p-2 float-right social-icon'  /></a>
          </Col>
        </Row>
        <Row>
          <Col className='text-center px-5 py-2'>
            <img alt="Okina Logo" src="/images/logo.png" className='mx-auto img-fluid logo' />
          </Col>
        </Row>
        <Row>
          <Col className='text-center text-light my-5'>
            <h4>Exclusive access to projects, merch, products, events, business solutions and more.</h4>
          </Col>
        </Row>
        <Row className='mx-md-5 px-md-5 mt-md-5 pb-5 mb-4' x>
          <Col className='text-center px-5 pb-5'>
            <img alt="Claim Header" src="/images/claim.png" className='img-fluid pb-5' /> <br />
            <h3>ACCESS PASS</h3>
            <h4 class="gold">GOLD</h4>
            <Mint />
          </Col>
          {/* <Col className='text-center text-light px-5 mx-20' sm={12} md={6}>
            <img alt="Okina Pass Header" src="/images/access-pass-ruby.png" className='img-fluid pb-4' /> <br />
            <img alt="Okina Pass Image" src="/images/pass.png" className='img-fluid' />
            <h3 className='pt-5'>Eligible for STZY / FKCN Generative holders. <br /> Max 1 claim per wallet.</h3>
          </Col> */}
        </Row>
        <Row className="mb-5">
          <Col sm={12} md={4}>

          </Col>
          <Col className="pt-md-5 footer text-center" sm={12} md={4}>
            Okina Creative Co. Steezy Ape Gang 2022 <br /> All rights reserved
          </Col>
          <Col className='text-md-end pt-md-4 pe-md-4 text-center my-3' sm={12} md={4}>
            <img alt="Okina Logo Small" src="/images/logo-small.png" className='pe-5' />
            <img alt="Bryan Vee Signature" src="/images/bryan-vee-sig.png" />
          </Col>
        </Row>
      </Container>
    </ThemeProvider>
  );
}

export default App;
