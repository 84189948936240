import React, { useState } from 'react';
import OkinaPassService from '../services/okina-pass-service.ts';
import { ethers } from 'ethers';
import abi from "../data/abi";
import appConfig from "../app-config";
import Button from 'react-bootstrap/Button';


function Mint() {

  const [account, setAccount] = useState("");
  const [minting, setMinting] = useState(false);
  const [mintStatus, setMintStatus] = useState(null);
  const [proofs, setProofs] = useState(null);
  const [chainChecked, setChainChecked] = useState(false);
  const { ethereum } = window;
 
  async function checkIfWalletIsConnected() {
    try {
      if(account) {
        return;
      }
      const { ethereum } = window;

      if (!ethereum || !ethereum.request) {
        console.log("Make sure you have metamask!");
        return;
      }

      const accounts = await ethereum.request({ method: 'eth_accounts' });

      if (accounts.length !== 0) {
        setAccount(accounts[0]);
        console.log("Found an authorized account:", account);
      } else {
        console.log("No authorized account found")
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function checkChain() {
    if(chainChecked) {
      return;
    }
    const { ethereum } = window;
    if (!ethereum || !ethereum.request) {
      return;
    }
    let connectedChainId = await ethereum.request({ method: 'eth_chainId' });
    console.log("Connected to chain " + connectedChainId);
    if (connectedChainId !== appConfig.chainId) {
      alert("You are not connected to the correct network!");
    }
    setChainChecked(true);
  }

  async function claimPass() {
    let contract;

    try {
      if (ethereum) {
        setMinting(true);
        const provider = new ethers.providers.Web3Provider(ethereum);
        const signer = provider.getSigner();
        contract = new ethers.Contract(appConfig.contractAddress, abi, signer);
        const proof = proofs.find(x => !x.claimed);
        
        let txn = await contract.claimPass(proof.tokenId, proof.proof);
        await txn.wait();

        alert("Claim Successful!");
        setMintStatus("success");
      } else {
        throw new Error("Failed to conenct to your wallet");
      }
    } catch (err) {
      if(err.reason) {
        alert(err.reason.replace("execution reverted: ", ""));
      } else if(err.message) {
        alert(err.message.replace("MetaMask Tx Signature: ", ""));
      } else {
        alert(JSON.stringify(err));
      }
    } finally {
      setMinting(false);
    }
  }

  async function connectWallet() {
    if (account === "") {
      try {
        if (!ethereum || !ethereum.request) {
          alert("MetaMask wallet was not detected.  Please install MetaMask and try again.");
          return;
        }
        const accounts = await ethereum.request({ method: "eth_requestAccounts" });
        console.log("Connected", accounts[0]);
        setAccount(accounts[0]);
      } catch (error) {
        console.log(error);
        return;
      }
    }
  }

  async function useMerkleProof() {
    React.useEffect(() => {
      async function fetchData() {
        if(!proofs && account) {
          const x = await OkinaPassService.getMerkleProofs(account);
          setProofs(x);
        }
      }
      fetchData();
    }, [account]);
  }

  checkIfWalletIsConnected();
  checkChain();
  useMerkleProof();

  if(!account) {
    return (
      <div className='text-center'>
        <div className='py-5 text-light'>
          <h3>No Wallet Connected</h3>
        </div>
        <Button variant="primary" size="lg" onClick={connectWallet}>Connect Wallet</Button>{' '}
      </div>
    )
  } if(minting) {
    return (<div>
      <img src="/images/loading.gif" alt="loading..." /> 
      <h3 className='text-light'>
        Please confirm the transaction in your wallet and wait for it to complete.
      </h3>
    </div>)
  } else {
    if(proofs == undefined) {
      return (<div><img alt="loading..." src="/images/loading.gif" /></div>)
    } else if(mintStatus === "success") {
      return ( <h3 className="text-light pt-4">
        <div className='pb-5'> { account }</div>
        Claim successful! <br /> <br />
        <a href={appConfig.openSeaCollection} target="_blank" className='text-light'>View your pass on OpenSea</a>
      </h3>)
    } else {
      if(proofs.length > 0) {
        const proof = proofs.find(x => !x.claimed);
        if(proof) {
          return (
            <div className='text-light'>
               <h3 className='pb-4'>{ account }</h3>
               <h2>TOTAL</h2>
               <h1 className="total pb-4">1</h1>
               <h3>Price 0 ETH</h3>
               <h3 className='pb-4'>Excluding gas fees</h3>
               <Button variant="primary" size="lg" onClick={claimPass}>CLAIM</Button>{' '}
            </div>)
        } else {
          return ( <h3 className="text-light pt-4">
          <div className='pb-5'> { account }</div>
          You already claimed your pass.
        </h3>)
        }
      } else {
        return (
          <h3 className="text-light pt-4">
            <div className='pb-5'> { account }</div>
            You are not eligible to claim a pass.
          </h3>
        )
      }
    }
  }
}

export default Mint;